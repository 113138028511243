'use strict';

import Bloodhound from 'corejs-typeahead';
import {translate} from '@elements/translations';


const defaultValues = {
    hint: true,
    highlight: true,
    minLength: 2,
    autocompleteText: 'title'
};

export function initInScope($scope) {
    let $input = $scope.find('.js-typeahead__input');
    let categories = $input.data('categories');


    if (!categories || !Array.isArray(categories)) {
        return;
    }

    let options = {
        ...defaultValues
    };
    let dataSets = [];

    categories.map((category) => {
        let bloodhound = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('text'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: $input.data('queryurl'),
                wildcard: '%QUERY',
                filter: function (response) {
                    if (response && response[category.name] && response[category.name]) {
                        return response[category.name];
                    }
                    return [];
                }
            }
        });

        dataSets.push({
            name: category.name,
            limit: Number.MAX_VALUE, /*workaround for https://github.com/twitter/typeahead.js/issues/1232*/
            display: defaultValues.autocompleteText,
            source: bloodhound,
            templates: {
                header: (data) => {
                    let query = (category.overviewUrl.indexOf("?") < 0 ? "?" : "&") + "q=" + data.query;

                    return ('<div class="typeahead-heading d-flex justify-content-between">' +
                        '<a href="' + category.overviewUrl + query + '" class="typeahead-heading__text mb0">' +
                        '<span class="typeahead-heading__icon text-primary ' + category.icon + '"></span><span>' + category.title + '</span></a>' +
                        (category.overviewUrl
                            ? (
                                '<a href="' + category.overviewUrl + query + '" class="typeahead-heading__link mb0">' +
                                '<span class="">' + category.overviewUrlTitle + '</span><span class="icon icon-arrow-long ml-2" aria-hidden="true"></span>' +
                                '</a>'
                            )
                            : '') +
                        '</div>')
                },
                suggestion: (data) => {
                    return (
                        '<div class="typeahead-suggestion">' + data.title + '</div>')
                },
                error: () => {
                    return '<div class="typeahead-suggestion"></div>'
                }
            }
        })
    });

    let renderCategories = [];

    let focusedSuggestion = null;
    $input.typeahead(options, dataSets)
        .on('typeahead:asyncrequest', (event, query, dataset) => {
            $input.closest('form').addClass('is-loading');
            focusedSuggestion = null;
        })
        .on('typeahead:open', (event, query, dataset) => {
            $('body').addClass('typeahead--open');
            focusedSuggestion = null;
        })
        .on('typeahead:close', (event, query, dataset) => {
            $('body').removeClass('typeahead--open');
        })
        .on('typeahead:render', (event, suggestions, flag, dataset) => {
            if (!renderCategories.filter(e => e.name === dataset).length > 0) {
                renderCategories.push({
                    "name": dataset,
                    "items": []
                });
            }

            suggestions.forEach(function (item, key) {
                renderCategories.map(obj => {
                    if (obj.name === item.type) {
                        obj.items.push(item);
                    }
                })
            });
        })
        .on('typeahead:asynccancel typeahead:asyncreceive', () => {
            $input.closest('form').removeClass('is-loading');
        })
        .on('typeahead:select typeahead:autocompleted', function (typeaheadObj, data) {
            if (data['url']) {
                $input.closest('form').addClass('is-loading');
                location.href = data.url;
            }
        }).on('typeahead:cursorchange', function (evt, suggestion) {
            focusedSuggestion = suggestion;
        })
        .on('keydown', function (evt) {
            if (evt.code === "Enter") {
                if (!focusedSuggestion) {
                    let $firstSuggestion = $('.typeahead-suggestion').eq(0);
                    if ($firstSuggestion && $firstSuggestion.length) {
                        evt.preventDefault();
                        $firstSuggestion.trigger('click');
                    }
                }
            }
        });
}

