"use strict";
export function initInScope ($scope) {
    let $container = $scope.find('.js-download-teaser');

    $container.each(function(container) {
        let $btn = $(this).find('.js-download-teaser__btn');
        let url = $btn.data('segment-url');
        $btn.on('click', function () {
            $.ajax({
                url: url,
                type: 'GET',
            });
        });
    });
}