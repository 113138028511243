let $btn;

export function init() {
    $btn = $('.js-scroll-to-top');
    console.log('scroll btn', $btn);

    $btn.on('click', function () {
        console.log('CLICKED');
        $('html, body').animate({scrollTop : 0}, 800);
        return false;
    });
}