let isRunning = false;
let $bannerAffix;
let $navbarAffix;
let lastScrollPosition = 0;
let navbarHeight;
let defaultTopPosition = 0;
let $bannerLines;

const selectors = {
    affixBanner: '.js-affix-banner',
    affixNavbar: '.js-affix-navbar',
    bannerLines: '.js-banner-line'
}

export function init() {
    $bannerAffix = $(selectors.affixBanner);
    $navbarAffix = $(selectors.affixNavbar);
    defaultTopPosition = $navbarAffix.height();
    $bannerAffix.css('top', defaultTopPosition);
    $bannerAffix.attr('data-affix-offset', defaultTopPosition);

    $(window).one('scroll', function () {
        setBannerTopAndOffset();
    });

    window.addEventListener('resize', function () {
        setBannerTopAndOffset();
        updateDom();
    });

    // correctly set base-condition for further calculations
    setBannerTopAndOffset();
    updateDom();

    if ($bannerAffix) {
        bannerToggleAffix($bannerAffix);
    }

    $bannerLines = $(selectors.bannerLines);
    let previousMousePosition = null;
    let isDragging = false;
    $bannerAffix.on('mousedown', function () {
        isDragging = true;
        $bannerLines.each(function () {
            // get the current progress of the animation -> translateX value and save it into the data attribute
            let translateX = $(this).css('transform').split(',')[4];
            $(this).attr('data-translate-x', translateX);
            $(this).css('transform', 'translateX(' + translateX + 'px)');
            $(this).removeClass('is-animating');
        })
    })

    $bannerAffix.on('mousemove', function (event) {
        if (!isDragging) {
            return
        }

        if (previousMousePosition === null) {
            previousMousePosition = event.clientX;
            return
        }

        let mousePosition = event.clientX;
        let delta = mousePosition - previousMousePosition;
        previousMousePosition = mousePosition;

        // move the lines by the delta
        $bannerLines.each(function () {
            let translateX = $(this).attr('data-translate-x');
            $(this).css('transform', 'translateX(' + (parseInt(translateX) + delta) + 'px)');
            $(this).attr('data-translate-x', parseInt(translateX) + delta);

            if(Math.abs(translateX) > $(this).width()) {
                $(this).css('transform', 'translateX(0px)');
                $(this).attr('data-translate-x', 0);
            }

            else if(translateX > 0) {
                // moving in the other direction -> starting point to the end
                $(this).css('transform', 'translateX(' + $(this).width() * (-.99) + 'px)');
                $(this).attr('data-translate-x', $(this).width() * (-.99));
            }
        })
    })

    $bannerAffix.on('mouseup', function () {
        previousMousePosition = null;
        isDragging = false;
        $bannerLines.each(function () {
            $(this).addClass('is-animating');

            // calculate the progress of the animation and the remaining time for this iteration to keep the same speed
            let translateX = $(this).attr('data-translate-x');
            let width = $(this).width();
            let duration = 35;
            let progress = Math.abs(translateX) / width;
            let remainingTime = duration * (1 - progress);
            $(this).css('animation-duration', remainingTime + 's');
        })
    })

    $bannerAffix.on('mouseout', function(event) {
        if(!isDragging) {
            return
        }

        previousMousePosition = null;
        isDragging = false;
        $bannerLines.each(function () {
            $(this).addClass('is-animating');
        })
    })

    $bannerLines.each(function () {
        $(this).on('animationend', function() {
            console.log("animationend fired!");
            $(this).removeClass('is-animating');
            // set the animation duration to the default value -> 35s
            $(this).css('animation-duration', '35s');

            // and all transforms back to zero
            $(this).css('transform', 'translateX(0px)');
            $(this).attr('data-translate-x', 0);

            let that = $(this)
            setTimeout(function() {
                that.addClass('is-animating');
            }, 0)
        })
    })
}

function setBannerTopAndOffset() {
    let newTopPosition = $navbarAffix.height();
    $bannerAffix.css('top', newTopPosition);
    $bannerAffix.attr('data-affix-offset', newTopPosition);
}

function bannerToggleAffix($bannerAffix) {
    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

function interval() {
    let scrollTopPosition = $(window).scrollTop();
    if (lastScrollPosition === scrollTopPosition) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = scrollTopPosition;
    updateDom();
    requestAnimationFrame(interval);
}

function updateDom() {
    let offset = $navbarAffix.attr('data-affix-offset');
    let scrollTopPosition = $(window).scrollTop();

    $navbarAffix.isAffix = offset <= scrollTopPosition;

    if ($navbarAffix.isAffix) {
        setAffix();
    } else {
        unsetAffix();
        setBannerTopAndOffset();
        // console.log("unset affix");
    }
}

export function setAffix() {
    $bannerAffix.addClass('is-affix');
}

export function unsetAffix() {
    $bannerAffix.removeClass('is-affix');
}