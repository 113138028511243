import 'slick-carousel';

export function initInScope ($scope) {
    let $sliders = $scope.find('.js-media-slider');

    $sliders.each(function () {
        let $sliderWrapper = $(this).closest('.js-media-slider__wrapper');

        $(this).slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            fade: false,
            draggable: true,
            infinite: true,
            prevArrow: $sliderWrapper.find('.slider__arrow.arrow-prev'),
            nextArrow: $sliderWrapper.find('.slider__arrow.arrow-next'),
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 1501,
                    settings: {
                        slidesToShow: 5,
                    },
                }
            ]
        });
    });
}
