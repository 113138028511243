let isRunning = false;
let $navbarAffix;
let lastScrollPosition = 0;
let navbarHeight;
let defaultTopPosition = 0;

export function init() {
    $navbarAffix = $('.js-affix-navbar');
    let $navbarContainer = $navbarAffix.closest('.js-affix-navbar__placeholder');
    defaultTopPosition = $navbarContainer.offset().top;

    $(window).one('scroll', function () {
        setNavbarHeight();
    });

    if ($navbarAffix) {
        navbarToggleAffix($navbarAffix);
    }

    window.addEventListener('resize', function () {
        setNavbarHeight();
        updateDom();
    });

    // Correctly set base-condition for further calculations
    setNavbarHeight();
    updateDom();
}

function setNavbarHeight() {
    let $navbarContainer = $('.js-affix-navbar__placeholder');
    $navbarContainer.css('overflow', 'hidden');
    $navbarContainer.height('auto');
    navbarHeight = $navbarContainer[0].getBoundingClientRect().height;
    $navbarContainer.css('overflow', '');
    $navbarContainer.height(navbarHeight);
}

function navbarToggleAffix($navbarAffix) {
    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

function interval() {
    let scrollTopPosition = $(window).scrollTop();
    if (lastScrollPosition === scrollTopPosition) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = scrollTopPosition;
    updateDom();
    requestAnimationFrame(interval);
}

function updateDom() {
    let offset = $navbarAffix.attr('data-affix-offset');
    let scrollTopPosition = $(window).scrollTop();

    $navbarAffix.isAffix = offset <= scrollTopPosition;

    if ($navbarAffix.isAffix) {
        setAffix();
    } else {
        unsetAffix();
        setNavbarHeight();
        // console.log("unset affix");
    }
}

export function setAffix() {
    $navbarAffix.addClass('is-affix');
}

export function unsetAffix() {
    $navbarAffix.removeClass('is-affix');
}