import $ from 'jquery';
// import 'popper.js'; test
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/
import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as fileInput from '@elements/file-input';
app.modules.fileInput = fileInput.initInScope;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as certificates from './scripts/certificates';
app.modules.certificates = certificates.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as commerceConnector from './scripts/commerceConnector';
app.modules.commerceConnector = commerceConnector.initInScope;

import * as scrollTop from './scripts/scrollTop';
app.modules.scrollTop = scrollTop.initInScope;

import * as liveChat from './scripts/live-chat';
app.modules.liveChat = liveChat.initInScope;

import * as toggleTextOnCollapse from './scripts/toggleTextOnCollapse';
app.modules.toggleTextOnCollapse = toggleTextOnCollapse.initInScope;

import * as cookieFirst from './scripts/cookieFirst';
app.modules.cookieFirst = cookieFirst.initInScope;

import * as cookieFirstOxomiOverlay from './scripts/cookieFirstOxomiOverlay';
app.modules.cookieFirstOxomiOverlay = cookieFirstOxomiOverlay.init;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as productGrid from '@elements/product-grid';
app.productGrid = productGrid.init;
app.modules.productGrid = productGrid.initInScope;

import * as productGridQuickFilter from './scripts/product-grid-quickfilter';
app.modules.productGridQuickFilter = productGridQuickFilter.initInScope;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as mediaSlider from './scripts/media-slider';
app.modules.mediaSlider = mediaSlider.initInScope;

import * as scrollToTop from './scripts/touch-terminal/scrollToTop';
app.modules.scrollToTop = scrollToTop.init;

import * as productTeaserSlider from './scripts/touch-terminal/product-teaser-slider';
app.modules.productTeaserSlider = productTeaserSlider.initInScope;

import * as wysiwygSlider from './scripts/wysiwyg-slider';
app.modules.wysiwygSlider = wysiwygSlider.initInScope;

import * as productSlider from './scripts/product-slider';
app.modules.productSlider = productSlider.initInScope;

import * as slider from './scripts/slider';
app.modules.slider = slider.initInScope;

import * as imgZoom from './scripts/imgZoom';
app.modules.imgZoom = imgZoom.initInScope;

import * as productThumbSlider from './scripts/productThumbSlider';
app.modules.productThumbSlider = productThumbSlider.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as customValidation from './scripts/customValidation';
app.modules.customValidation = customValidation.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import * as backButton from './scripts/backButton';
app.modules.backButton = backButton.initInScope;

import * as loadMore from './scripts/loadMore';
app.modules.loadMore = loadMore.initInScope;

import * as touchNav from '@elements/touch-nav';
app.modules.touchNav = touchNav.initInScope;

/*
import { showCookieBar } from "@elements/cookie-permissions";
if (!_config.admin && !_config.editmode) {
    showCookieBar();
}
*/

import * as tracking from '@elements/tracking';
let options = {}; // optional options
app.modules.tracking = tracking.initGtmEventTracking(options);

import * as trackDealerMap from './scripts/trackDealerMap';
app.modules.trackDealerMap = trackDealerMap.initInScope;

import * as affixNavbar from './scripts/affixNavbar';
app.affixNavbar = affixNavbar.init;

import * as affixNavbarTouchTerminal from './scripts/touch-terminal/affixNavbar-touch-terminal';
app.affaffixNavbarTouchTerminal = affixNavbarTouchTerminal.init;

import * as imgGallery from './scripts/touch-terminal/imgGallery';
app.imgGallery = imgGallery.init;

import * as affixBanner from './scripts/affixBanner';
app.affixBanner = affixBanner.init;

import * as a11yBanner from './scripts/a11yBanner';
app.a11yBanner = a11yBanner.init;

import * as ajaxForm from '@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.initInScope;

import * as ajaxFormMap from './scripts/ajax-form-map';
app.modules.ajaxFormMap = ajaxFormMap.initInScope;

import * as geoLocation from './scripts/geolocation';
app.modules.geoLocation = geoLocation.initInScope;

import * as affix from '@elements/affix';
if (matchMedia('(min-width: 768px)').matches) {
    app.modules.affix = affix.initInScope;
}

import * as nav from './scripts/nav';
app.nav = nav.init;

import * as screenSaver from './scripts/touch-terminal/screenSaver';
app.modules.screenSaver = screenSaver.init;

import * as svgConverter from './scripts/svgConverter';
app.modules.svgConverter = svgConverter.initInScope;

import * as regionMap from './scripts/regionMap';
app.modules.regionMap = regionMap.initInScope;

import * as stepForm from './scripts/stepForm';
app.modules.stepForm = stepForm.initInScope;

import * as ajaxFormScroll from './scripts/ajax-form-scroll';
app.modules.ajaxFormScroll = ajaxFormScroll.initInScope;

import * as datepicker from '@elements/datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as datepickerRange from '@elements/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as showTemplateOnChange from './scripts/showTemplateOnChange';
app.showTemplateOnChange = showTemplateOnChange.init;
app.modules.showTemplateOnChange = showTemplateOnChange.initInScope;

import * as showIfChecked from './scripts/showIfChecked';
app.modules.showIfChecked = showIfChecked.initInScope;

import * as scrollTo from './scripts/scrollTo';
app.modules.scrollTo = scrollTo.initInScope;

import * as rangeSlider from './scripts/rangeSlider';
app.modules.rangeSlider = rangeSlider.initInScope;

import * as focusOnModalShow from './scripts/focusOnModalShow';
app.modules.focusOnModalShow = focusOnModalShow.initInScope;

import * as productGridDropdownFilter from './scripts/product-grid-dropdown-filter';
app.modules.productGridDropdownFilter = productGridDropdownFilter.initInScope;

import * as menuAim from './scripts/menuAim';
app.modules.menuAim = menuAim.init;

import * as openModal from './scripts/openModal';
app.openModal = openModal.init;

import * as scrollNav from './scripts/scrollspy-nav';
app.scrollNav = scrollNav.init;

import * as hotspots from './scripts/hotspot';
app.modules.hotspots = hotspots.initInScope;

import * as downloadTeaser from './scripts/download-teaser';
app.modules.downloadTeaser = downloadTeaser.initInScope;

import * as transformIn from './scripts/transform-in';
app.modules.transformIn = transformIn.initInScope;

import * as lightboxDynamic from './scripts/lightbox-dynamic';
app.modules.lightboxDynamic = lightboxDynamic.init;

import * as fadeIn from './scripts/fade-in';
app.modules.fadeIn = fadeIn.initInScope;

import {translate} from '@elements/translations';

import * as typeahead from './scripts/typeahead';
app.modules.typeahead = typeahead.initInScope;

import * as typeaheadInteractivePosition from './scripts/typeahead-interactive-position'
app.modules.typeaheadInteractivePosition = typeaheadInteractivePosition.initInScope;

import * as tooltip from './scripts/tooltip'
app.modules.tooltip = tooltip.initInScope;

import * as recaptcha from './scripts/recaptcha';
app.modules.recaptcha = recaptcha.initInScope;

import * as numberSpinner from '@elements/number-spinner';
app.modules.numberSpinner = numberSpinner.initInScope;

import * as interestsAccordion from './scripts/interests-accordion';
app.modules.interestsAccordion = interestsAccordion.initInScope;

import * as togglePasswordVisibility from './scripts/toggle-password-visibility';
app.modules.togglePasswordVisibility = togglePasswordVisibility.initInScope;

import * as wishlist from './scripts/wishlist';
app.wishlist = wishlist.init;
app.modules.wishlist = wishlist.initInScope;

import * as articleItems from './scripts/article-items';
app.modules.articleItems = articleItems.initInScope;

import * as articleItemNumberSpinner from './scripts/article-item-number-spinner';
app.modules.articleItemNumberSpinner = articleItemNumberSpinner.initInScope;

import * as getGeolocation from './scripts/get-geolocation';
app.modules.getGeolocation = getGeolocation.initInScope;

import * as checkout from './scripts/checkout';
app.modules.checkout = checkout.initInScope;

import * as conditionalForm from './scripts/conditional-form';
app.modules.conditionalForm = conditionalForm.initInScope;

import * as comparisonArea from './scripts/comparison-area';
app.modules.comparisonArea = comparisonArea.initInScope;

import * as agb from './scripts/agb';
app.modules.agb = agb.initInScope;

import * as smoothScroll from "@elements/smooth-scroll";
smoothScroll.init({
    scrollOffset: 82
});

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }


    initModulesInScope();
})($);