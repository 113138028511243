let $element;
let $images;
let $video;

export function init() {
    let timer;
    let index = 0;

    $element = $('.js-screen-saver');
    $images = $('.js-screen-saver__img');
    $video = $('.js-screen-saver__video');

    let $timerCountdown = $element.data('timer-countdown');
    let $imageDisplayTime = $element.data('image-display-time');

    function showScreenSaver() {
        $element.css('display', 'block');

        if ($images) {
            index = 0;
            showNextImg();
        }
    }

    function hideScreenSaver() {
        $element.css('display', 'none');
    }

    function showNextImg() {
        $images.removeClass('active');
        $images.eq(index).addClass('active');

        index = (index + 1) % $images.length;

        setTimeout(showNextImg, $imageDisplayTime);
    }

    function resetTimer() {
        clearTimeout(timer);
        timer = setTimeout(showScreenSaver, $timerCountdown);
        hideScreenSaver();
    }

    $(window).on('load mousemove mousedown click scroll keypress', resetTimer);

    if ($video) {
        $video.on('pause ended volumechange seeking seeked ratechange fullscreenchange', resetTimer);
    }
}