let $navbarAffix;

export function init() {
    $navbarAffix = $('.js-affix-navbar__no-scroll');
    $navbarAffix.addClass('is-affix');

    window.addEventListener('resize', function () {
        setNavbarHeight();
    });

    setNavbarHeight();
}

function setNavbarHeight() {
    let $navbarContainer = $('.js-affix-navbar__no-scroll-placeholder');
    $navbarContainer.css('overflow', 'hidden');
    $navbarContainer.height('auto');
    let navbarHeight = $navbarContainer[0].getBoundingClientRect().height;
    $navbarContainer.css('overflow', '');
    $navbarContainer.height(navbarHeight);
}