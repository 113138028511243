let $sidebarFigures;

export function init() {
    $sidebarFigures = $('.js-product-gallery-item');

    $sidebarFigures.each(function() {
        $(this).on('click', function() {
            $sidebarFigures.removeClass('active');
            $(this).addClass('active');

            const id = $(this).attr('data-img-id');
            $('.js-product-gallery-display-img').addClass('d-none');

            const $largeImageFig = $('#large-image-' + id);
            if ($largeImageFig.length) {
                $largeImageFig.removeClass('d-none');
            }
        });
    });
}